<template>
    <div class="content-wrapper">
        <bo-page-title></bo-page-title>
        <div class="content pt-0">
            <div class="">
                <div class="card">
                    <div class="bg-white card-header">
                        <div class="row align-items-center">
                            <div class="col-md-5">
                                <div class="form-row align-items-center">
                                    <div class="col-md-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                                    
                                    <div class="col-md-8" v-if="isshowTable('ar_reg_date')">
                                        <div class="input-group">
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="datePickerConfig.locale"
                                                :autoApply="datePickerConfig.autoApply"
                                                v-model="dateRange"
                                                :opens="'right'"
                                                :ranges="datePickerConfig.ranges"
                                                @update="updateValues"
                                                class="form-control"
                                            >
                                                <template v-slot:input="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-8">
                                    <div class="form-row">
                                        <div class="col-md-auto">
                                        <div class="form-group mb-0">
                                            <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="form-group mb-0">
                                            <button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover ><i class="icon-table2"></i></button>
                                        </div>
                                    </div>

                                    <div class="col-md-4" v-if="isshowTable('mpo_name')">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.process_status" :clearable="true" :options="Config.mr.configStatusTidakHadir" label="text" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                    <input class="form-control"
                                        v-model="filter.search"
                                        @input="doSearch"
                                        placeholder="Ketik Nama/No. RM Pasien"
                                    />
                                    <div class="form-control-feedback">
                                        <i class="icon-search4 text-indigo"></i>
                                    </div>
                                        
                                    <b-button
                                        class="ml-1 d-inline-flex align-items-center"
                                        variant="outline-success"
                                        id="resetBtn"
                                        @click="doResetData()"
                                    >Reset</b-button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive sticky-table">
                        <table class="table table-bordered table-striped table-hover table-sm text-uppercase patient-table">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
                                    <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                    <th v-if="isshowTable('ars_name')">Status</th>
                                    <th v-if="isshowTable('mpo_name')">Nama Poli</th>
                                    <th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
                                    <th v-if="isshowTable('ap_code')">No. RM</th>
                                    <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                                    <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                                    <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                    <th v-if="isshowTable('ap_usia')">Usia</th>
                                    <th v-if="isshowTable('cg_label')">Jenis Kelamin</th>
                                    <th v-if="isshowTable('ar_penunjang')">Penunjang</th>
                                </tr>
                            </thead>
                            <tbody v-if="!loadingTable">
                                <template v-for="(v,k) in dataReg">
                                    <tr :key="k" :class="v.ar_process_status == 'ABSEN' ? 'table-info' : v.ar_process_status == 'VOID' ? 'table-danger' : ''">
                                        <td>{{k+1}}</td>
                                        <td v-if="isshowTable('ar_reg_code')">
                                        {{v.ar_reg_code||"-"}}
                                        <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                        <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                        <td v-if="isshowTable('ap_fullname')">
                                        <a v-if="v.ar_is_void !== 'Y'" @click="requestPanggil(v,v.ar_is_called)" href="javascript:;" data-popup="tooltip" title="" data-toggle="modal" data-target="#confirmPatient" class="font-weight-semibold border-bottom" data-original-title="Panggil Pasien">{{uppercaseWord(v.ap_fullname)||"-"}}</a>
                                        <span v-else>{{uppercaseWord(v.ap_fullname)||"-"}}</span>
                                        <p>{{v.ap_nik||"-"}}</p>
                                        </td>
                                        
                                        <td v-if="isshowTable('ars_name')">
                                        <span :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
                                        </td>

                                        <td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>    <td v-if="isshowTable('ap_nik')">{{uppercaseWord(v.ap_nik)||"-"}}</td>
                                      
                                        <td v-if="isshowTable('ar_reg_date')">
                                        {{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>      
                                        <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                        <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                        <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                        <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                        <td v-if="isshowTable('ap_usia')">
                                        {{v.ap_usia_with_ket||"-"}}
                                        <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                        </td>
                                        <td v-if="isshowTable('cg_label')">{{v.cg_label}}</td>
                                        <td v-if="isshowTable('ar_penunjang')">
                                        <div v-if="v.ar_is_ppa_radiologi == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Radio</span>
                                            </div>
                                            <div v-if="v.ar_is_ppa_lab == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Lab</span>
                                            </div>
                                            <div v-if="v.ar_is_ppa_fisio == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Fisioterapi</span>
                                            </div>
                                            <span v-if="v.ar_is_ppa_radiologi !== 'Y' && v.ar_is_ppa_lab !== 'Y' && v.ar_is_ppa_fisio !== 'Y' && v.ar_is_ppa_dietisien !== 'Y'"> - </span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-if="loadingTable">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="loadingInvinite">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                                <tr>
                                    <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                        Not Found Data
                                    </th>
                                </tr>                
                            </tbody>                
                        </table>
                    </div>
                </div>
                
                <b-modal 
                    v-model="openFilter"
                    :title="'Filter Kolom'"
                    size="sm"
                    ok-title="Terapkan"
                    @ok="terapkan()"
                >
                    <div class="row">
                        <div class="col-md-12">
                        <div class="wrap_line">
                            <div class="form-check p-0">
                                <label class="form-check-label d-flex">
                                <b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled" name="radio-inline-left" />
                                Tampilkan Semua
                                </label>
                            </div>
                            <hr class="my-2">
                            <div class="pl-2">
                                <b-form-group class="mb-0">
                                    <b-form-checkbox-group
                                        id="checkbox-block"
                                        v-model="selectedFilter"
                                        :options="optionFilter"
                                        name="filter"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                            </div>
                        </div>
                    </div>
                </b-modal>

                <b-modal 
                    v-model="openPanggil"
                    :title="'Konfirmasi Ketidakhadiran Pasien'"
                    size="md"
                    body-class="p-2"
                    hide-footer
                >
                    <div class="modal-body">
                        <div class="text-center">
                        <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                            <i class="icon-megaphone"></i>
                        </div>
                        <h6 class="mb-0">Pasien No. {{rowEdit.ar_reg_code}} atas Nama</h6>
                        <h3 class="font-weight-semibold text-primary">{{rowEdit.ap_fullname||"-"}}</h3>
                        <h6>Dinyatakan <strong class="text-uppercase">Tidak Hadir</strong> untuk kunjungan yang dijadwalkan pada
                            {{rowEdit.ar_reg_date | moment("dddd, DD MMMM YYYY")}}, dengan catatan berikut</h6>
                        <table class="table table-bordered mb-2">
                            <tr>
                            <td>
                                <div class="result_tab text-left">
                                <h4>Catatan Ketidakhadiran Pasien</h4>
                                <p>{{rowEdit.ar_notes||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab text-left">
                                <h4>{{rowUser.bu_level == 3 ? 'Perawat' : 'Dokter'}} Poli</h4>
                                <p>{{rowUser.bu_full_name||"-"}}</p>
                                <span class="text-muted">{{rowUser.mpo_name||"-"}}</span>
                                </div>
                            </td>
                            </tr>
                        </table>
                        <p>Lakukan konfirmasi akhir ketidakhadiran pasien dengan menekan tombol "Konfirmasi"</p>
                        <div class="mt-3">
                            <a href="javascript:;" @click="openPanggil = false" class="btn">Batal</a>
                            <a href="javascript:;" @click="konfirmasiVoid" class="btn btn-success btn-labeled btn-labeled-left">
                            <b><i class="icon-checkmark"></i></b>
                            Konfirmasi
                            </a>
                        </div>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
// import draggable from 'vuedraggable'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            rowUser: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'ar_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'ars_name' },
                { text: 'Nama Poli', value: 'mpo_name' },
                { text: 'Tanggal Daftar', value: 'ar_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Payor', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'cg_label' },
                { text: 'Penunjang', value: 'ar_penunjang' },
            ],
            selectedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            }
        }
    },
  	components:{DateRangePicker},
    methods: {
        doRefreshData(){
            this.apiGet(true)
        },
        otherConditional(v){
            return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date) && v.ar_is_created_rekam_medis !== 'Y'
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_void_${this.user.id}`,JSON.stringify(this.acceptedFilter))
            this.apiGet()
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },100),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(isLoad = false){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isLoad){
                this.loadingTable = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage, 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
                this.totalData = resp.dataReg.total
                
                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }
                }else{
                    this.dataReg = resp.dataReg.data
                }   
            })
            
            Gen.apiRest(
                "/get/"+'Master'+
                '/poli'
            ).then(res=>{
                let resp = res.data
                this.mPoli = resp.mPoli
            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Dashboard'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
        },
        
        requestPanggil(v,isCalled){
            this.rowEdit = v
           
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'get-user', id: v.ar_absen_by}}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.rowUser = resp.row
                this.openPanggil = true
                this.loadingOverlay = false        
            })

                
        },
        konfirmasiVoid(){
            this.rowEdit.type = 'void-pasien'

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: this.rowEdit}, 
                "POST"
            ).then(res=>{
                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet()
                }
                
                this.openPanggil = false

                this.$swal({
                    title: `Berhasil`,
                    text: 'Berhasil Mengubah Status menjadi VOID',
                    icon: 'success',
                })
            })

           
        },


        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        redirect(v){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id, rmNo: v.arm_id,
            typeKajian:v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan' }, query: {regId: v.ar_id} }).catch(()=>{})
        },
        
        endDrag() {
            Gen.apiRest(
                "/do/" +this.modulePage, {
                data: {
                    type: 'sort-data',
                    data: this.dataReg
                }
                },
                "POST"
            )
        },
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_void_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        this.filter.process_status = 'ALL'
        
        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet()
        }
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
    },
    watch:{
        $route(){
           this.apiGet()
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>